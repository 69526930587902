<template>
  <div>
    <div class="header">
      <div class="links">
        <div class="link"><a href="/shop">Shop</a></div>
        <div class="link hide-on-very-small-screens">
          <a href="/#location">Location</a>
        </div>
        <div class="link"><a href="/#contact">Contact Us</a></div>
        <div class="link">
          <a
            target="_blank"
            href="https://www.google.com/maps/d/viewer?mid=1QIvTedNP39GWsgwvCVbDrtV8sZMYfSLY&ll=52.466780397135715%2C1.4232594000000098&z=11"
            >Delivery Map</a
          >
        </div>
      </div>

      <div class="contact-details">
        <div class="contact-detail">
          <a href="tel:01986897944"
            ><b-icon-telephone class="mr-1" /><span
              class="d-none d-lg-inline-block"
              >01986 897944</span
            ></a
          >
        </div>
        <div class="contact-detail">
          <a href="https://g.page/giddensandthompson?share"
            ><b-icon-geo class="mr-1" /><span class="d-none d-lg-inline-block"
              >Get directions</span
            ></a
          >
        </div>

        <div class="contact-detail">
          <a href="#opening-hours"
            ><b-icon-clock class="mr-1" /><span class="d-none d-lg-inline-block"
              >Opening hours</span
            ></a
          >
        </div>

        <div class="contact-detail" v-show="basketQuantity > 0">
          <b-button href="/basket" size="sm" variant="basket"
            ><b-icon-basket-3 class="mr-1" />
            <transition name="slide-fade" mode="out-in">
              <div :key="basketQuantity" class="basket-quantity">
                {{ basketQuantity }}
              </div>
            </transition>
          </b-button>
        </div>
      </div>
    </div>

    <!-- Push the rest of the content down by the same height as the fixed or absolutely positioned header -->
    <div class="header-spacer"></div>

    <div
      class="banner"
      :class="{
        ['d-none']: bannerSize === 'none',
        ['banner-small']: bannerSize === 'small',
        ['banner-normal']: bannerSize === 'normal',
      }"
    >
      <div class="banner-image"></div>
      <div class="banner-overlay"></div>
    </div>

    <div class="hero">
      <a href="/"
        ><div
          class="logo"
          :class="{ [`banner-hidden`]: bannerSize === 'none' }"
        ></div
      ></a>

      <div class="hero-content" :class="{ ['d-none']: bannerSize === 'none' }">
        <slot name="hero-content"></slot>
      </div>
    </div>

    <div class="content">
      <slot name="content"></slot>
    </div>

    <div class="site-map">
      <b-row>
        <b-col class="section text-left" sm="auto">
          <div class="heading" id="opening-hours">OPENING HOURS</div>
          <table class="site-map-table">
            <tbody>
              <tr>
                <td>Monday</td>
                <td>9:00 hrs &horbar; 17:00 hrs</td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>9:00 hrs &horbar; 17:00 hrs</td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>9:00 hrs &horbar; 17:00 hrs</td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>9:00 hrs &horbar; 17:00 hrs</td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>9:00 hrs &horbar; 17:00 hrs</td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>9:00 hrs &horbar; 17:00 hrs</td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>CLOSED</td>
              </tr>
            </tbody>
          </table>
        </b-col>

        <!-- Christmas opening hours -->
        <!--
        <b-col class="section text-left" sm="auto">
          <div class="heading" id="opening-hours">
            OPENING HOURS (DURING CHRISTMAS)
          </div>
          <table class="site-map-table">
            <tbody>
              <tr>
                <td>Monday 23<sup>rd</sup> Dec</td>
                <td>9:00 hrs &horbar; 18:00 hrs</td>
              </tr>
              <tr>
                <td>Tuesday 24<sup>th</sup> Dec (Christmas Eve)</td>
                <td>9:00 hrs &horbar; 14:00 hrs</td>
              </tr>
              <tr>
                <td>Wednesday 25<sup>th</sup> Dec (Christmas Day)</td>
                <td>CLOSED</td>
              </tr>
              <tr>
                <td>Thursday 26<sup>th</sup> Dec (Boxing Day)</td>
                <td>CLOSED</td>
              </tr>
              <tr>
                <td>Friday 27<sup>th</sup> Dec</td>
                <td>10:00 hrs &horbar; 17:00 hrs</td>
              </tr>
              <tr>
                <td>Saturday 28<sup>th</sup> Dec</td>
                <td>09:00 hrs &horbar; 17:00 hrs</td>
              </tr>
              <tr>
                <td>Sunday 29<sup>th</sup> Dec</td>
                <td>CLOSED</td>
              </tr>
              <tr>
                <td>Monday 30<sup>th</sup> Dec</td>
                <td>10:00 hrs &horbar; 16:00 hrs</td>
              </tr>
              <tr>
                <td>Tuesday 31<sup>st</sup> Dec (New Year's Eve)</td>
                <td>10:00 hrs &horbar; 14:00 hrs</td>
              </tr>
              <tr>
                <td>Wednesday 1st Jan 2025 (New Year's Day)</td>
                <td>CLOSED</td>
              </tr>
              <tr>
                <td>Thursday 2nd Jan 2025</td>
                <td><i>Regular hours resume</i></td>
              </tr>
            </tbody>
          </table>
        </b-col>
        -->

        <b-col class="col text-left" sm="auto">
          <div class="section">CONTACT US</div>
          <table class="site-map-table">
            <tbody>
              <tr>
                <td>36B Earsham Street</td>
              </tr>
              <tr>
                <td>Bungay</td>
              </tr>
              <tr>
                <td>Suffolk</td>
              </tr>
              <tr>
                <td>NR35 1AQ</td>
              </tr>
              <tr>
                <td>
                  <a href="tel:01986897944"
                    ><b-icon-telephone class="mr-1" /><span
                      >01986 897944</span
                    ></a
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <a href="mailto:shop@giddensandthompson.co.uk"
                    >shop@giddensandthompson.co.uk</a
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <a href="/privacy-policy">Privacy policy</a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BIconTelephone,
  BIconGeo,
  BIconClock,
  BIconBasket3,
} from "bootstrap-vue";
export default {
  props: {
    basketQuantity: {
      type: Number,
      required: false,
      default: 0,
    },
    bannerSize: {
      type: String,
      required: false,
      default: () => "normal",
      validator: (val) => ["none", "small", "normal", "full"].includes(val),
    },
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  components: {
    BCol,
    BRow,
    BButton,
    BIconTelephone,
    BIconGeo,
    BIconClock,
    BIconBasket3,
  },
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
@import "../_variables.scss";

.header {
  background-color: $fruity-grey;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  padding-left: 2vw;
  padding-right: 2vw;
  height: 55px;

  a {
    color: white;
  }

  .links,
  .contact-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .link,
    .contact-detail {
      white-space: nowrap;
      overflow: auto;
    }

    .link:not(:first-child) {
      margin-left: 2.5vw;
    }

    .contact-detail:not(:last-child) {
      margin-right: 2vw;
    }
  }

  @media only screen and (max-width: 400px) {
    .links,
    .contact-details {
      font-size: 14px;
    }

    .btn-basket {
      padding: 4px;
    }
  }

  @media only screen and (max-width: 350px) {
    .links,
    .contact-details {
      font-size: 12px;
    }

    .btn-basket {
      padding: 3px;
    }
  }
}

.header-spacer {
  height: 55px;
  width: 100%;
}

@media only screen and (max-width: 350px) {
  .header-spacer {
    height: 45px;
  }

  .header {
    height: 45px;
  }
}

.banner {
  transition-delay: 0s;
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease-out;
  position: relative;
  height: 383px;
  overflow: hidden;
  width: 100%;

  .banner-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    );
  }

  .banner-image {
    width: 100%;
    min-height: 100%;
    height: auto;
    background-image: url(../img/banner.jpg);
    background-size: cover;
    background-position: 50%;
  }

  &.banner-small {
    max-height: 20vh;
  }

  &.banner-normal {
    max-height: 40vh;
  }
}

.hero {
  $logo-size: 120px;

  position: relative;
  text-align: center;
  background-color: $fruity-yellow;
  padding: 2em;
  padding-top: calc(#{$logo-size / 2} + 20px);
  min-height: ($logo-size + 40px);

  .hero-content {
    max-width: 800px;
    margin: 0 auto;
  }

  .logo {
    background-image: url(../img/logo.jpg);
    background-size: cover;
    width: $logo-size;
    height: $logo-size;
    position: absolute;
    left: calc(50% - #{$logo-size / 2});
    top: #{0 - ($logo-size / 2)};
    border-radius: 50%;
    border: 6px solid lighten($fruity-yellow, 10%);
  }

  .logo.banner-hidden {
    top: 20px;
  }
}

.basket-quantity {
  display: inline-block;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter {
  transform: translateY(-20px);
  &.basket-quantity {
    color: #d7e9b0;
  }
}

.slide-fade-leave-to {
  transform: translateY(20px);
}

.site-map {
  @extend %centered-section;
  background-color: $fruity-grey;
  color: white;
  font-size: 0.8em;

  .heading {
    margin-bottom: 1em;
  }

  .site-map-table td {
    padding-right: 10px;
    text-wrap: nowrap;
    vertical-align: top;
  }

  .section {
    margin-bottom: 1em;
  }
}

/**
 * 480px is the point at which there are too many links
 */
@media only screen and (max-width: 480px) {
  .hide-on-very-small-screens {
    display: none;
  }
}
</style>
